const _ = require("lodash");

export const CountrySplit = () => {
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue && sessionParsedValue.entity_info
    let uniq_country = _.uniqBy(sessionArr, function(e) {
        return e.c_id;
    });
    return uniq_country
}

export const HomeRedirect = () => {
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    console.log(callername,'callername');
    if(callername && callername.login_response){
        const getUrl = callername.login_response.menu['Static']
        let temp = _.filter(getUrl, { form_name: 'home' })
        let tempval = temp &&temp.length>0&& temp[0].form_url
        return tempval
    }
}